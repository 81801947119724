import * as React from 'react'
import { StatusPage } from '../components/statusPage'

const NotPermittedPage = () => {
  return (
    <StatusPage code={'403'}>
      <p>
        This page may exist, but you are not allowed to access it at this point
        in time. If you think this is wrong, please contact Alexander Franke.
      </p>

      <p>You&apos;ll find lots to explore on the home page.</p>
    </StatusPage>
  )
}

export default NotPermittedPage

export const Head = () => <title>Not allowed</title>
